import { useState, useEffect } from 'react';
import Button from './CustomButton';
import SubscriptionBanner from './SubscriptionBanner';
import Badge from 'react-bootstrap/Badge';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Card from 'react-bootstrap/Card';
import { UNAUTH_TESTTIME, TESTTIME, RSM_PASS_PERCENTAGE, RR_PASS_PERCENTAGE, VC_PASS_PERCENTAGE, CLIENT_RELATIVE_PATH, BASE_URL } from '../config/config';
import { getPercentage } from '../lib/helperFunctions';
import { useIsAuthenticated, useAuthUser, useAuthHeader } from 'react-auth-kit';
import { Link } from 'react-router-dom';

const styles = {
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}

function MultipleChoice({questions, subscription, daysLeft, testType}){
  const isAuthenticated = useIsAuthenticated()();
  const user = useAuthUser()();
  const authHeader = useAuthHeader();
  
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState('');
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [RSMScore, setRSMScore] = useState(0);
  const [RRScore, setRRScore] = useState(0);
  const [VCScore, setVCScore] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const [submittedAnswer, setSubmittedAnswer] = useState(false);

  // const [timeRemaining, setTimeRemaining] = useState(subscription ? TESTTIME : UNAUTH_TESTTIME); // 1 hour in seconds
  const [timeRemaining, setTimeRemaining] = useState(subscription ? TESTTIME : UNAUTH_TESTTIME); //

  useEffect(() => {

    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timer);
          setShowResult(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [currentQuestion]); // Restart timer when the question changes

  const handleOptionSelect = (option) => {
    setSelectedAnswer(option);
  };

  const handleNextQuestion = () => {
    if (selectedAnswer === questions[currentQuestion].correctAnswer) {
      setScore(score + 1);
      if(questions[currentQuestion].type === "rsm"){
        setRSMScore(RSMScore + 1);
      }else if(questions[currentQuestion].type === "rr") {
        setRRScore(RRScore + 1);
      } else if(questions[currentQuestion].type === "vc") {
        setVCScore(VCScore + 1);
      }
    }

    if (currentQuestion < questions.length - 1) {
      setCurrentQuestion(currentQuestion + 1);
      setSelectedAnswer('');
      setSubmittedAnswer(false); // Reset submitted answer status for the next question
    } else {
      setShowResult(true);
    }
  };

  const handleQuestionSubmit = () => {
    if (selectedAnswer) {
      setSelectedAnswers({
        ...selectedAnswers,
        [currentQuestion]: selectedAnswer,
      });
      setSubmittedAnswer(true); // Mark that the answer has been submitted
    }
  };

  const restartTest = () => {
    window.location.reload(); 
    // setCurrentQuestion(0);
    // setSelectedAnswer('');
    // setSelectedAnswers({});
    // setScore(0);
    // setShowResult(false);
    // setSubmittedAnswer(false);
    // setTimeRemaining(isAuthenticated ? TESTTIME : UNAUTH_TESTTIME); // Reset timer to 1 hour
  };
  const progressPercentage = ((currentQuestion + 1) / questions.length) * 100;
  const optionAlphabet = (index) => String.fromCharCode(65 + index); 

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(
      2,
      '0'
    )}`;
  };

  
// Function to count occurrences of objects with the same value
const countOccurrences = (arr) => {
    return arr.reduce((acc, obj) => {
        const type = obj.type; //get the question type value
        acc[type] = (acc[type] || 0) + 1; //count type value
        return acc;
    }, {}); //return as obj
    };
const occurrences = countOccurrences(questions);
  
  return (
     
    <div style={styles.body}>
      <Card style={{ width: '10vm', display: 'flex', height: '100%', padding:"20px 10px", margin:"10px 10px"}}>
      <SubscriptionBanner subscription={subscription} daysLeft={daysLeft} product={"Learner's Simulation Test"}/> 
      {showResult ? (
        <div>
          <h2>
            
            { testType === "test1" ? "Code 8 " : ""} 
            { testType === "test2" ? "Code 10 & 14 " : ""} 
            
            Learner's Simulation Test Completed!
            
            </h2>
          <div>
            <i>Your score: {score} out of {questions.length}</i>
            <hr/>
            <b>Mark Break Down: </b><br/>
            <i>Road Signs & Markings:</i> {RSMScore}/{occurrences.rsm} ({getPercentage(RSMScore, occurrences.rsm)}%) - <Badge bg={(getPercentage(RSMScore, occurrences.rsm) >= RSM_PASS_PERCENTAGE) ? "success" : "danger"}>{(getPercentage(RSMScore, occurrences.rsm) >= RSM_PASS_PERCENTAGE) ? "Pass" : "Fail"}</Badge><br/>
            <i>Rules Of The Road:</i> {RRScore}/{occurrences.rr} ({getPercentage(RRScore, occurrences.rr)}%) - <Badge bg={(getPercentage(RRScore, occurrences.rr) >= RR_PASS_PERCENTAGE) ? "success" : "danger"}>{(getPercentage(RRScore, occurrences.rr) >= RR_PASS_PERCENTAGE) ? "Pass" : "Fail"}</Badge><br/>
            <i>Vehicle Controls:</i> {VCScore}/{occurrences.vc} ({getPercentage(VCScore, occurrences.vc)}%) - <Badge bg={(getPercentage(VCScore, occurrences.vc) >= VC_PASS_PERCENTAGE) ? "success" : "danger"}>{(getPercentage(VCScore, occurrences.vc) >= VC_PASS_PERCENTAGE) ? "Pass" : "Fail"}</Badge><br/>
            <hr/>
            <b>Final Results:</b> <Badge bg={(getPercentage(RSMScore, occurrences.rsm) < RSM_PASS_PERCENTAGE || getPercentage(RRScore, occurrences.rr) < RR_PASS_PERCENTAGE || getPercentage(VCScore, occurrences.vc) < VC_PASS_PERCENTAGE) ? "danger" : "success"}>{(getPercentage(RSMScore, occurrences.rsm) < RSM_PASS_PERCENTAGE || getPercentage(RRScore, occurrences.rr) < RR_PASS_PERCENTAGE || getPercentage(VCScore, occurrences.vc) < VC_PASS_PERCENTAGE) ? "Failed" : "Passed"}</Badge><br/>
            NB. <i>Road Signs & Markings Pass Rate = {RSM_PASS_PERCENTAGE}%, Rules Of The Road = {RR_PASS_PERCENTAGE}%, and Vehicle Controls = {VC_PASS_PERCENTAGE}%</i>
          </div>
          {!isAuthenticated && 
          <Link to={`${BASE_URL + CLIENT_RELATIVE_PATH}/learners-test-1`}>
            <Button text={"Get More Time"} color="dark"/>
          </Link>
          }
          <Button onClick={restartTest} text={"Restart Test"} color="primary"/>
        </div>
      ) : (
        <div>
          <h2>Question {currentQuestion + 1}</h2>
          <p>Time remaining: <b>{formatTime(timeRemaining)}</b></p>

          <ProgressBar now={parseInt(progressPercentage)} label={`${parseInt(progressPercentage)}%`} />
          <br/>
          {("image" in questions[currentQuestion]) && 

                
                <div style={{display: 'flex', justifyContent: 'center'}}>     
                  <div className='col-sm-3 col-md-3'>
                      {/* <img
                          src={questions[currentQuestion].image}
                          alt={`Road Sign ${currentQuestion + 1}`}
                          style={{ maxWidth: '100%', height: 'auto'}}
                          loading="lazy"
                      /> */}

                      <Card.Img src={questions[currentQuestion].image}
                      alt={`Road Sign ${currentQuestion + 1}`} className="card-image" style={{ width: '200px', height: '200px' }} />

                  </div>
                </div>
          }
          <p>
              {
                /* check if  question contains a line breack(\n)*/
              questions[currentQuestion].question.split("\n").map((sentance,i) => <>
              {(i===0) ? <b>{sentance}</b> : sentance}
              <br/></>)
              }
          </p>
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {questions[currentQuestion].options.map((option, index) => (
                <>
              <li
                key={index}
                style={{
                    display: "inline-block",
                    paddingRight: "4px",
                    border: selectedAnswer === option ? '4px solid lightgrey' : '4px solid transparent',
                    borderRadius: '5px',
                    marginBottom: '10px',
                    backgroundColor: 'white',
                  borderColor:
                    submittedAnswer &&
                    (selectedAnswers[currentQuestion] === option ||
                      option === questions[currentQuestion].correctAnswer)
                      ? option === questions[currentQuestion].correctAnswer
                        ? 'lightgreen' // highlight correct answer
                        : 'lightcoral' // highlight incorrect answer
                      : 'white',
                }}
                
              >
                <label>
                  <input
                    type="radio"
                    value={option}
                    checked={selectedAnswer === option}
                    onChange={() => handleOptionSelect(option)}
                    style={{ display: 'none' }}
                  />
                  <span style={{fontSize: '12px', fontWeight: 'bold', padding: '5px', backgroundColor: 'lightgrey', borderRadius: '5px'}}>
                    {optionAlphabet(index)}.
                  </span>{' '}
                  {option}
                </label>
              </li><br/></>
            ))}
          </ul>
          <Button onClick={handleQuestionSubmit} text={"Submit Answer"} disabled={!selectedAnswer}/>
          <Button onClick={handleNextQuestion} text={(currentQuestion < questions.length - 1)?"Next Question":"Show Results"} disabled={!submittedAnswer || !selectedAnswer} color={"dark"}/>
        </div>
      )}
      </Card>
  </div>
    

  );
};

export default MultipleChoice;
